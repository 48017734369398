<template>
    <Dialog header="Settings" v-model:visible="display" :modal="true" :closable="true" class="group-settings-modal">
        <div class="group-actions-switch-container">
            <div class="row">
                    <div class="column">
                        <span>Accepting New Members:</span>
                    </div>
                    <div class="column">
                        <InputSwitch v-model="acceptingMembers" class="group-actions-switch"
                            @change="updateGroupSettings" />
                    </div>
                </div>
            <template v-if="selectedGroup.educational || isPremiumGroup() ">
                <div class="row">
                    <div class="column">
                        <span>Resources Enabled:</span>
                    </div>
                    <div class="column">
                        <InputSwitch v-model="resourcesEnabled" class="group-actions-switch"
                            @change="updateGroupSettings" />
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <span>Courses Enabled:</span>
                    </div>
                    <div class="column">
                        <InputSwitch v-model="coursesEnabled" class="group-actions-switch"
                            @change="updateGroupSettings" />
                    </div>
                </div>
                <div class="row" v-if="selectedGroup.systemGroup">
                    <div class="column">
                        <span>Position:</span>
                    </div>
                    <div class="column">
                        <InputNumber v-model="position" class="group-actions-switch" :maxFractionDigits="0"
                            @update:modelValue="updateGroupSettings"  :allowEmpty="false" style="width:35px;margin-right:5px;" inputStyle="width:35px;margin-right:5px;"/>
                    </div>
                </div>
            </template>
            <template v-if="isPortfolioFeatureEnabled()">
                
                <div class="row">
                    <div class="column">
                        <h4 class="portfolio-header">Portfolios:</h4>
                    </div>
                    <div class="column">
                        <InputSwitch v-model="groupPortfoliosEnabled" class="group-actions-switch"
                            @change="updateGroupSettings" />
                    </div>
                </div>
                <template v-if="groupPortfoliosEnabled">
                    <div class="row">
                        <div class="column">
                            <span>Aggregate Portfolios Enabled:</span>
                        </div>
                        <div class="column">
                            <InputSwitch v-model="aggregatePortfoliosEnabled" class="group-actions-switch"
                                @change="updateGroupSettings" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column">
                            <span>Collaborative Portfolios Enabled:</span>
                        </div>
                        <div class="column">
                            <InputSwitch v-model="collaborativePortfoliosEnabled" class="group-actions-switch"
                                @change="updateGroupSettings" />
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="column">
                            <span>Select portfolio for group performance section:</span>
                        </div>
                    </div>
                    <Dropdown class="group-feed-dropdown" :options="currentGroupPortfolios" optionLabel="name" v-model="defaultGroupPortfolio"
                        @change="updateGroupSettings" />   -->
                </template>
            </template>
        </div>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputSwitch from 'primevue/inputswitch';
//import Dropdown from 'primevue/dropdown';

import GroupService from '../../../service/GroupService';

import {mapState} from 'vuex';

import InputNumber from 'primevue/inputnumber';
import { hasPremiumGroupSubscription, isINVRSGroup} from '../';

const portfolioTypes = {
    paperAnalysis: "paperAnalysis",
    paperTrading: "paperTrading"
};

export default {
    name: 'GroupSettingsModal',
    components: {
        Dialog,
        InputSwitch,
        //Dropdown,
        InputNumber
    },
    props: {
        selectedGroup: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            display: false,

            groupPortfoliosEnabled: false,
            aggregatePortfoliosEnabled: false,
            collaborativePortfoliosEnabled: false,

            defaultGroupPortfolio: null,
            initialDefaultGroupPortfolioId: null,
            showOnProfile: false,
            sharePortfolioIsMandatory: false,
            allowedPortfolioTypes: [],
            portfolioTypes: portfolioTypes,
            acceptingMembers: false,
            resourcesEnabled: false,
            coursesEnabled: false,
            position: 1,

        };
    },

    computed: {
        ...mapState(['currentGroupPortfolios']),
    },

    watch: {
        currentGroupPortfolios(){
            this.initialDefaultGroupPortfolioId = this.selectedGroup.defaultGroupPortfolioId;
            if(this.initialDefaultGroupPortfolioId){
                this.defaultGroupPortfolio = this.currentGroupPortfolios.find(portfolio => portfolio.groupPortfolioId == this.initialDefaultGroupPortfolioId);
            } 
            if(!this.defaultGroupPortfolio){ 
                this.defaultGroupPortfolio = this.currentGroupPortfolios[0];
            }
        },
    },

    methods: {
        
        isPremiumGroup() {
            return hasPremiumGroupSubscription(this.selectedGroup?.subscriptionId);
        },
         isPortfolioFeatureEnabled(){
             
            return (isINVRSGroup(this.selectedGroup.groupId) || this.selectedGroup.educational || hasPremiumGroupSubscription(this.selectedGroup.subscriptionId))
                &&
                (
                    (!this.isTeamGroup() && this.isGroupPortfolioEnabled())
                    || 
                    (this.isTeamGroup() && this.isTeamPortfolioEnabled())
                );
        },

        isGroupPortfolioEnabled(){
            return this.$store.state.featureSettings.groupPortfolioEnabled;
        },

        isTeamPortfolioEnabled(){
            return this.$store.state.featureSettings.teamPortfolioEnabled;
        },

        isTeamGroup() {
            return this.selectedGroup.groupTypeId == 2;
        },

        open() {
            this.display = true;

           this.initSettings();
        },

        initSettings(){
            this.groupPortfoliosEnabled = this.selectedGroup.groupPortfoliosEnabled;
            this.aggregatePortfoliosEnabled = this.selectedGroup.aggregatePortfoliosEnabled;
            this.collaborativePortfoliosEnabled = this.selectedGroup.collaborativePortfoliosEnabled;
            this.initialDefaultGroupPortfolioId = this.selectedGroup.defaultGroupPortfolioId;
            this.position = this.selectedGroup.position;
            this.resourcesEnabled = this.selectedGroup.resourcesEnabled;
            this.coursesEnabled = this.selectedGroup.coursesEnabled;
            this.acceptingMembers = this.selectedGroup.acceptingMembers;
        },

        updateGroupSettings() {
            GroupService.updateGroupSettings(this.selectedGroup.groupId, this.defaultGroupPortfolio?.groupPortfolioId, null, null, null, null, 
            this.groupPortfoliosEnabled, this.aggregatePortfoliosEnabled, this.collaborativePortfoliosEnabled,
            this.acceptingMembers, this.resourcesEnabled, this.position, this.coursesEnabled)
                .then(resp => {
                    if (resp.data.status == "success" && resp.data.group) {
                        this.$store.commit('SET_SELECTED_GROUP', resp.data.group);
                        // console.log("updateGroupSettings", resp.data.group, this.selectedGroup);
                    } else {
                        this.$toast.add({ severity: 'error', summary: `Unable to update group settings due to an unexpected error.`, life: 2500, });
                    }
                });
        },
    },
};
</script>

<style>
.group-settings-modal {
    font-family: 'Trebuchet MS', 'Verdana';
    border-radius: 16px;
    min-width: 380px;
}

.group-settings-modal .p-dialog-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.group-settings-modal .p-dialog-content {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: hidden;
    padding: 0px;
    height: 100%;
}
</style>
<style scoped>
.group-feed-dropdown {
    margin-bottom: 8px;
}

.group-actions-switch-container {
    display: grid;
    width: auto;
    min-width: 200px;
    margin: 0px 16px 16px;
}

.create-portfolio-switch,
.group-actions-switch {
    margin-left: auto;
    display: flex;
}

.row {
    display: flex;
    align-items: center;
    padding: 4px 0px;
    min-height: 32px;
}

.check-box-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0px;
}

.p-checkbox {
    margin-right: 4px !important;
}

.column {
    flex-grow: 1;
}

.portfolio-header {
    margin-bottom: 0px;
}

.portfolio-dropdown {
    margin: 10px 0px 0px;
}
</style>